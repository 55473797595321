import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Modal, Row, Col, Form } from "react-bootstrap";
import JSZip from "jszip";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import DatePicker from 'react-datepicker';
import { deleteFromAPI, getFromAPI, postToAPI, putToAPI } from '../Utils/utils';
import { toast } from 'react-toastify';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

export default function DestinationAssignment({activeTab}) {
    // Modal
    const [showDestinationAssignmentModal, setshowDestinationAssignmentModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [DestinationAssignmentData, setDestinationAssignmentData] = useState();
    const [DestinationMappingData, setDestinationMappingData] = useState();
    const [DestinationData, setDestinationData] = useState();
    const [VehicleAssignmentData, setVehicleAssignmentData] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [EditDestinationAssignID, setEditDestinationAssignID] = useState();
    const [DeleteDestinationAssignID, setDeleteDestinationAssignID] = useState();
    const [validated, setValidated] = useState(false);
    const handleDateFormat = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const InitialDestinationAssignInputData = {
        destination_id: 0,
        vehicle_assignment_id: 0,
        date_assigned: handleDateFormat(new Date()),
        is_active: true,
    }
    const [DestinationAssignInputData, setDestinationAssignInputData] = useState(InitialDestinationAssignInputData);

    const fetchDestinationAssignmentData = () => {
        getFromAPI('DestinationAssignment/').then(data => {
            const sortedDAData = data.sort((a, b) => {
                return new Date(b.date_assigned) - new Date(a.date_assigned);
            });
            setDestinationAssignmentData(sortedDAData);
        }).catch(error => {
            console.error('Error fetching Destination Assignment data:', error);
        });
    }

    const fetchDestinationMappingData = () => {
        getFromAPI('DestinationMapping/').then(data => {
            setDestinationMappingData(data);
        }).catch(error => {
            console.error('Error fetching Destination mapping data:', error);
        });
    }

    const fetchVehicleAssignmentData = () => {
        getFromAPI('VehicleAssignment/').then(data => {
            setVehicleAssignmentData(data);
        }).catch(error => {
            console.error('Error fetching vehicle assignment data:', error);
        });
    }

    const fetchDestinationData = () => {
        getFromAPI('Destination/').then(data => {
            setDestinationData(data);
        }).catch(error => {
            console.error('Error fetching destination data:', error);
        });
    }

    const handleshowDestinationAssignmentModal = (title) => {
        setModalTitle(title);
        setshowDestinationAssignmentModal(true);
    };
    const handleCloseModal = () => {
        setIsEdit(false);
        setValidated(false);
        UpdateDestinationAssignDataTable();
        setEditDestinationAssignID(null);
        setDeleteDestinationAssignID(null);
        setDestinationAssignInputData(InitialDestinationAssignInputData);
        setshowDeleteModal(false);
        setshowDestinationAssignmentModal(false);
    };

    const handleshoweditDestinationAssignmentModal = (title, id) => {
        setIsEdit(true);
        setEditDestinationAssignID(id);
        getFromAPI(`DestinationAssignment/?id=${id}`).then(dataArr => {
            let data = dataArr[0];
            setDestinationAssignInputData({...DestinationAssignInputData, destination_id:data.destination_id, vehicle_assignment_id:data.vehicle_assignment_id, date_assigned:data.date_assigned, is_active:data.is_active});
        });
        handleshowDestinationAssignmentModal(title);
    }

    const handleDeleteData = (id) => {
        // Set the selected id and show the modal
        setDeleteDestinationAssignID(id);
        setshowDeleteModal(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const Response = await deleteFromAPI(`DestinationAssignment/${DeleteDestinationAssignID}/`);
            if (Response.status) {
                toast.success('Destination assignment data Successfully deleted');
                handleCloseModal();
            } else {
                toast.error('Failed to delete destination assignment data');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An unexpected error occurred');
        }
    };

    const handleAddDestinationAssignment = async (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
        } else {
            e.preventDefault(); // Prevent page reload
            setValidated(true);
            if(isEdit) {
                try {
                    const Response = await putToAPI(`DestinationAssignment/${EditDestinationAssignID}/`, DestinationAssignInputData);
                    if (Response.status) {
                        toast.success('Destination Assignment data Successfully updated');
                        handleCloseModal();
                    } else {
                        toast.error('Failed to update Destination Assignment data');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error('An unexpected error occurred');
                }
            } else {
                try {
                    const Response = await postToAPI("DestinationAssignment/", DestinationAssignInputData);
                    if (Response.status) {
                        toast.success('Destination Assignment data Added Successfully');
                        handleCloseModal();
                    } else {
                        toast.error('Failed to add Destination Assignment data');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error('An unexpected error occurred');
                }
            }
        }
    }

    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (DestinationAssignmentData && VehicleAssignmentData && DestinationData && !$.fn.dataTable.isDataTable("#DestinationAssignmentDataTable")) {
            $("#DestinationAssignmentDataTable").DataTable({
                dom: 'Bflrtip',
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    }
                ],
                data: DestinationAssignmentData.map((data, i) => [
                    i + 1,
                    VehicleAssignmentData.find(va => parseInt(va.id) === parseInt(data.vehicle_assignment_id))?.name || 'NA',
                    DestinationData.find(d => parseInt(d.id) === parseInt(data.destination_id))?.name || 'NA',
                    data.date_assigned,
                    data.id,
                    data.is_active,
                ]),
                columns: [
                    { title: "No." },
                    { title: "Vehicle Assignment" },
                    { title: "Destinations Assignment" },
                    { title: "Date" },
                    {
                        title: "Status",
                        render: function (data, type, row) {
                            let statusColor = row[5] ? 'success' : 'danger';
                            let statusText = row[5] ? 'Active' : 'in-Active';
                            return `
                            <span class="badge badge-soft-${statusColor}">${statusText}</span>
                          `;
                        }
                    },
                    {
                        title: "Action",
                        render: function (data, type, row) {
                            return `
                                <div class="d-flex">
                                    <button class="btn btn-soft-primary table-btn me-2"><i class="bi bi-pencil-square edit-vehicle-destinations" data-vd-id="${row[4]}"></i></button>
                                    <button class="btn btn-soft-danger table-btn"><i class="bi bi-trash3 delete-vehicle-destinations" data-vd-id="${row[4]}"></i></button>
                                </div>
                            `;
                        }
                    },
                ],
                autoWidth: false,
                columnDefs: [
                    {
                        targets: -1,
                        orderable: false,
                    },
                ],
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });
            // Event delegation to handle delete action
            document.querySelector('#DestinationAssignmentDataTable tbody').addEventListener('click', function (event) {
                if (event.target && event.target.classList.contains('edit-vehicle-destinations')) {
                    const DestinationAssignment_id = event.target.getAttribute('data-vd-id');
                    handleshoweditDestinationAssignmentModal("Edit Destination Assignment", DestinationAssignment_id);
                }
                if (event.target && event.target.classList.contains('delete-vehicle-destinations')) {
                    const DestinationAssignment_id = event.target.getAttribute('data-vd-id');
                    handleDeleteData(DestinationAssignment_id);
                }
            });

        }
    }, [DestinationAssignmentData, VehicleAssignmentData, DestinationData]);

    const UpdateDestinationAssignDataTable = () => {
        const DADT = $('#DestinationAssignmentDataTable').DataTable();
        getFromAPI("DestinationAssignment/")
            .then(response => {
                const sortedDAData = response.sort((a, b) => {
                    return new Date(b.date_assigned) - new Date(a.date_assigned);
                });
                setDestinationAssignmentData(sortedDAData);
                let DTData = sortedDAData.map((data, i) => [
                    i + 1,
                    VehicleAssignmentData.find(va => parseInt(va.id) === parseInt(data.vehicle_assignment_id))?.name || 'NA',
                    DestinationData.find(d => parseInt(d.id) === parseInt(data.destination_id))?.name || 'NA',
                    data.date_assigned,
                    data.id,
                    data.is_active,
                ]);
                DADT.clear().rows.add(DTData).draw();
            })
            .catch(error => {
            console.error('Error:', error);
            });
    }

    useEffect(()=>{
        fetchDestinationAssignmentData();
        fetchDestinationMappingData();
        fetchVehicleAssignmentData();
        fetchDestinationData();
    }, []);

    useEffect(()=>{
        fetchDestinationAssignmentData();
        fetchDestinationMappingData();
        fetchVehicleAssignmentData();
        fetchDestinationData();
    }, [activeTab]);

    return (
        <>
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between my-3">
                <Card.Title className="mb-0">Destination Assignment</Card.Title>
                <Button variant="primary" onClick={() => handleshowDestinationAssignmentModal("Add Destination Assignment")}>
                    <i className="bi bi-plus-square me-2"></i> Add Destination Assignment
                </Button>
            </div>

            <Table responsive bordered className="mb-0 table-nowrap" id="DestinationAssignmentDataTable">
                <thead className="table-light">
                </thead>
            </Table>

            {/* Vehicle Modal */}
            <Modal centered size="lg" show={showDestinationAssignmentModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={(e) => handleAddDestinationAssignment(e)}>
                    <Modal.Body className="pb-0">
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="form-space">
                                    <Form.Label>Vehicle Assignment</Form.Label>
                                    <Form.Select aas="select" name="VehicleType" value={DestinationAssignInputData.vehicle_assignment_id} onChange={(e)=>setDestinationAssignInputData({...DestinationAssignInputData, vehicle_assignment_id:e.target.value})} required isInvalid={validated && (DestinationAssignInputData.vehicle_assignment_id === 0)} >
                                        <option value="" >Select Vehicle Assignment</option>
                                        {VehicleAssignmentData && VehicleAssignmentData.filter(vm => vm.isActive) // Filter only the active vehicles
                                            .map((vm) => (
                                                <option key={vm.id} value={vm.id}>
                                                    {vm.name}
                                                </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select vehicle assignment.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="form-space">
                                    <Form.Label>Destination</Form.Label>
                                    <Form.Select aas="select" name="DriverName" value={DestinationAssignInputData.destination_id} onChange={(e)=>setDestinationAssignInputData({...DestinationAssignInputData, destination_id:e.target.value})} required isInvalid={validated && (DestinationAssignInputData.destination_id === 0)} >
                                        <option value="" >Select Destinations Assignment</option>
                                        {DestinationData && DestinationData.map((destination) => destination.isActive ? (
                                            <option key={destination.id} value={destination.id}>
                                                {destination.name}
                                            </option>
                                        ) : null )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select destination.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="form-space">
                                    <Form.Label>Date</Form.Label>
                                    <div className='input-icon-content'>
                                    <DatePicker value={DestinationAssignInputData.date_assigned} onChange={(date) => setDestinationAssignInputData({...DestinationAssignInputData, date_assigned:handleDateFormat(date)})} dateFormat="yyyy-MM-dd" showIcon />
                                        <i className="bi bi-calendar2-event input-icon"></i>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Check
                                    type="checkbox"
                                    id="default-checkbox"
                                    label="Is Active"
                                    checked={DestinationAssignInputData.is_active}
                                    onChange={(e)=>setDestinationAssignInputData({...DestinationAssignInputData, is_active:e.target.checked})}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="mt-1">
                        <Button variant="primary" type='submit' >Save</Button>
                        <Button variant="light" onClick={handleCloseModal}>Close</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* Delete Modal */}
            <Modal centered show={showDeleteModal} onHide={handleCloseModal}>
                <Modal.Body className="text-center">
                    <div className="avatar avatar-xxxl bg-border-soft-danger rounded-circle text-danger mx-auto ">
                        <i className="bi bi-trash"></i>
                    </div>
                    <h4 className="fw-semibold mt-4">Are you sure?</h4>
                    <p className="text-muted fs-18">Do you really want to delete this record?</p>
                    <div className="d-flex gap-3">
                        <Button variant="light w-50" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button variant="danger w-50" onClick={handleConfirmDelete}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
