import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Modal, Row, Col, Form } from "react-bootstrap";
import JSZip from "jszip";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { getFromAPI, postToAPI,putToAPI, deleteFromAPI, API_MEDIA_URL, companyName } from '../Utils/utils';
import { toast } from "react-toastify";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import logo from "../../images/logo-sm.png";

export default function Barcodes() {
    // Modal
    const [showBarcodeModal, setshowBarcodeModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [barCodeInputData,setBarCodeInputData] = useState({
        code : '',
        code_type : "QR",
        isActive : true,
    });
  
    const[barCodeToEdit,setBarCodeToEdit] = useState(null);
    const [barCodeToDelete,setBarCodeToDelete] = useState(null);
    const [barCodeData,setBarcodeData] = useState([]);
    const [DestinationData,setDestinationData] = useState([]);
    const [WardsData,setWardsData] = useState([]);
    const [ZonesData,setZonesData] = useState([]);

    const fetchBarCodeData = () => {
        getFromAPI('QRCode/').then(data => {
            if (data) {
                setBarcodeData(data);
            } else {
                console.error('Unexpected data structure:', data);
            }
        }).catch(error =>{
            console.error('Error fetching barcodes:', error);
        });
    };

    const fetchDestinationData = () => {
        getFromAPI(`destination/`).then(data => {
            if (data) {
                setDestinationData(data);
            } else {
                console.error('Unexpected data structure:', data);
            }
        }).catch(error => {
            console.error('Error fetching destinations:', error);
        });
    };

    const FetchZoneData = () => {
        getFromAPI('zone/').then(data => {
            setZonesData(data);
        }).catch(error => {
            console.error('Error fetching Zone data:', error);
        });
    }

    const FetchWardData = () => {
        getFromAPI('ward/').then(data => {
            setWardsData(data.ward);
        }).catch(error => {
            console.error('Error fetching Zone data:', error);
        });
    }

    useEffect(() => {
        fetchBarCodeData();
        fetchDestinationData();
        FetchZoneData();
        FetchWardData();
    }, []);

    const getDestinationdataByQRCodeId = (id) => {
        let destinationName, WardName, ZoneName = '';
        const destination = DestinationData.find(d => parseInt(d.qrCodeId) === parseInt(id));
        if (destination) {
            destinationName = destination.name;
            const ward = WardsData.find(w => parseInt(w.id) === parseInt(destination.wardId));
            if (ward) {
                WardName = ward.name;
                ZoneName = ward.zone.name;
            }
        }
        return {destinationName, WardName, ZoneName};
    };

    const getBase64ImageFromUrl = async (imageUrl) => {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
    };

    const exportTableToPDF = async () => {
        const doc = new jsPDF();
      
        // Define the data for the table
        const tableData = barCodeData.map((data) => {
            const {destinationName, WardName, ZoneName} = getDestinationdataByQRCodeId(data.id);
            return {
                code: data.code,
                type: data.code_type,
                destination: destinationName,
                WardName: WardName,
                ZoneName: ZoneName,
                imageUrl: API_MEDIA_URL + data.code_path, // Image URL
            }
        });

        const Companylogo = await getBase64ImageFromUrl(logo);
      
        // Fetch and convert images to Base64
        const formattedData = await Promise.all(
          tableData.map(async (item) => {
            const base64Image = await getBase64ImageFromUrl(item.imageUrl);
            return {
              code: item.code,
              type: item.type,
              destination: item.destination,
              Ward: item.WardName,
              Zone: item.ZoneName,
              image: base64Image,
            };
          })
        );
      
        //=============== Currently keep this code as it can help to set data in table format in pdf ==============
        // // Define the table columns
        // const columns = ['QR/Barcode Code', 'Type', 'QR image'];
      
        // // Prepare table rows with Base64 images
        // const rows = formattedData.map((item) => [
        //   item.code,
        //   item.type,
        //   { content: item.image, width: 30, height: 30 },
        // ]);

        // // Define a default image size
        // const imageWidth = 30;
        // const imageHeight = 30;
      
        // // Generate the table in the PDF
        // doc.autoTable({
        //   head: [columns],
        //   body: rows.map((row) => [
        //     row[0],
        //     row[1],
        //     { content: '', styles: { cellWidth: row[2].width, cellHeight: row[2].height } }, // For image
        //   ]),
        //   rowHeight: imageHeight + 10,
        //   didDrawCell: (data) => {
        //     // Check if we are in the "Image" column (index 2)
        //     if (data.row.section === 'body' &&data.column.index === 2 && formattedData[data.row.index].image) {
        //       // Get the cell position and add the image inside it
        //       data.row.height = Math.max(data.row.height, imageHeight + 10);
        //       doc.addImage(
        //         formattedData[data.row.index].image,
        //         'JPEG',
        //         data.cell.x + 2,   // Adjust x-position
        //         data.cell.y + 2,   // Adjust y-position
        //         imageWidth, imageHeight             // Width and height of the image
        //       );
        //     }
        //   },
        // });
      
        // Define image dimensions
        const imageWidth = 100;  // Width of the image
        const imageHeight = 100; // Height of the image
        const imageYPosition = 100; // Position on page for the image

        // First page: add company name as title
        doc.setFontSize(18);
        doc.addImage(Companylogo, 'JPEG', (doc.internal.pageSize.getWidth() / 2)-50, 10, 20, 20); 
        doc.text(companyName, doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' });

        // Loop through each data item and create a new page for each
        formattedData.forEach((item, index) => {
            if (index !== 0) {
            doc.addPage(); // Add a new page for each item, except the first one
            }

            doc.setFontSize(14);
            doc.text("Assigned Destination : " + (item.destination ? item.destination : 'No any destination is assigned to this QR'), 20, 40); 

            doc.setFontSize(12);
            doc.text("Ward : " + item.Ward, 20, 50); 
            doc.setFontSize(12);
            doc.text("Zone : " + item.Zone, 90, 50); 
            doc.setFontSize(12);
            doc.text("QR Code : " + item.code, 20, 60); 

            // doc.setFontSize(12);
            // doc.text(item.type, 20, 40); 

            // Add the image
            if (item.image) {
                doc.addImage(
                    item.image,
                    'JPEG',
                    (doc.internal.pageSize.getWidth() - imageWidth) / 2, // Center the image horizontally
                    imageYPosition, // Image position vertically
                    imageWidth,
                    imageHeight
                );
            }
        });

      
        // Save the PDF
        doc.save('barcode_data.pdf');
      };


    DataTable.Buttons.jszip(JSZip);
    useEffect(() => {
        if(barCodeData.length > 0) {
            const tableElement = $("#barcodeDataTable");
        
        if($.fn.dataTable.isDataTable(tableElement))
        {
            tableElement.DataTable().clear().destroy();
        }

        tableElement.DataTable({
                dom: 'Bflrtip',
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel',
                        exportOptions: {
                            columns: ':not(:last-child)' // Exclude the last column ("Action")
                        }

                    },
                    {
                        extend: 'pdfHtml5',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf',
                        exportOptions: {
                            columns: ':not(:last-child)' // Exclude the last column ("Action")
                        },
                        action: exportTableToPDF
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print',
                        exportOptions: {
                            columns: ':not(:last-child)' // Exclude the last column ("Action")
                        }
                    }
                ],
                data: barCodeData.map((data, i) => [
                    i + 1,
                    data.code,
                    data.code_type,
                    `
                        <img src="${API_MEDIA_URL + data.code_path}" width="100" height="100"/>
                    `,
                    `
                        <div class="d-flex">
                            <button class="btn btn-soft-primary table-btn me-2 edit-barcode" data-dest-id="${data.id}">
                                <i class="bi bi-pencil-square"></i>
                            </button>
                            <button class="btn btn-soft-danger table-btn delete-barcode" data-dest-id="${data.id}">
                                <i class="bi bi-trash3"></i>
                            </button>
                        </div>
                    `
                ]),
                columns: [
                    { title: "No." },
                    { title: "QR/Barcode Code" },
                    { title: "Type" },
                    { title: "QR image"},
                    {title: "Action"}
                ],
                autoWidth: false,
                columnDefs: [
                    {
                        targets: -1,
                        orderable: false,
                    },
                ],
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });
            // Event delegation to handle delete action
            tableElement.on('click', '.edit-barcode', function () {
                const barCodeId = $(this).data('dest-id');
                handleshowBarcodeModal("Edit BarCode", barCodeId, true);
            });

            // Handle delete button click
            tableElement.on('click', '.delete-barcode', function () {
                const barCodeId = $(this).data('dest-id');
                setBarCodeToDelete(barCodeId);
                setshowDeleteModal(true);
            });
        }
    }, [barCodeData]);

    const handleshowBarcodeModal = (title, barCode_id, isEdit = false) => {
        setModalTitle(title);
        setshowBarcodeModal(true);
        
        if(isEdit && barCode_id)
        {
            setBarCodeToEdit(barCode_id);
            getFromAPI(`QRCode/`).then(data => {
                if (Array.isArray(data)){
                    const barCodeData = data.find(barcode => barcode.id === barCode_id); 

                    if(barCodeData){
                        setBarCodeInputData({
                            code : barCodeData.code|| '',
                            code_type : barCodeData.code_type|| 'QR',
                            isActive : barCodeData.isActive|| true,
                        });
                     } else{
                        console.error('No barcode found with id:', barCode_id);
                    }

                }
                else{
                    console.error('Unexpected data structure:', data);
                }
            }).catch(error => {
                console.error('Error fetching barcode data:', error);
            });
        }
        else{
            setBarCodeToEdit(null);
            setBarCodeInputData({
                code : "",
                code_type : "QR",
                isActive :true,
            });
        }

    };

    const handleCloseModal = () => {
        setshowBarcodeModal(false);
        setValidated(false); // Reset validation state
        setBarCodeInputData({
            code: "",
            code_type: "QR",
            isActive: true,
        }); // Reset the form data
        setBarCodeToEdit(null); // Reset the edit state
    };
    
  
    const handleSaveBarcode = async (e) => {
        e.preventDefault(); // Prevent page reload
        const form = e.currentTarget;
        const formIsValid = form.checkValidity();
    
        if (!formIsValid) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setValidated(true);
    
            try {
                // Fetch all existing barcodes to check for duplicates
                const existingBarcodes = await getFromAPI("QRCode/"); // Assuming this API fetches all existing barcodes    
                // Check if the current barcode already exists (excluding the one being edited)
                const isDuplicate = existingBarcodes.some(barcode => 
                    barcode.code === barCodeInputData.code && barcode.id !== barCodeToEdit
                );
    
                if (isDuplicate) {
                    toast.error('Barcode code already exists,please use unique Barcode code', 'error');
                    return; // Stop further execution
                }
    
                let response;
                if (barCodeToEdit) {
                    response = await putToAPI(`add_or_edit_qrcode/?id=${barCodeToEdit}`, barCodeInputData);
                    if (response.status) {
                        toast.success('Barcode updated successfully', 'success');
                        fetchBarCodeData();
                    } else {
                        toast.error('Failed to update barcode', 'error');
                    }
                } else {
                    response = await postToAPI("add_or_edit_qrcode/", barCodeInputData);
                    if (response.status) {
                        toast.success('Barcode added successfully', 'success');
                        fetchBarCodeData();
                    } else {
                        toast.error('Failed to add barcode', 'error');
                    }
                }
                handleCloseModal();
            } catch (error) {
                console.error('Error saving barcode:', error);
            }
        }
    };
    

    //Confirm Delete
    const handeleConfirmDelete = async() => {
        try {
            await deleteFromAPI(`QRCode/${barCodeToDelete}/`);
            toast.success('Barcode deleted successfully','success');
            setshowDeleteModal(false);
            fetchBarCodeData();
        }
        catch (error) {
            console.error('Error deleting barcode:', error);

        }
    };

    // Check box
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (event) => {
        setBarCodeInputData({...barCodeInputData,isActive:event.checked});
        setIsChecked(event.target.checked);
    };

    return (
        <>
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between my-3">
                <Card.Title className="mb-0">Barcodes</Card.Title>
                <Button variant="primary" onClick={() => handleshowBarcodeModal("Add Barcode")}>
                    <i className="bi bi-plus-square me-2"></i> Add Barcode
                </Button>
            </div>

            <Table responsive bordered className="mb-0 table-nowrap" id="barcodeDataTable">
                <thead className="table-light">
                </thead>
            </Table>

            {/* Barcode Modal */}
            <Modal centered size="lg" show={showBarcodeModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSaveBarcode}>
                <Modal.Body className="pb-0">
                    <Row>
                    <Col lg={12}>
                        <Form.Group className="form-space">
                        <Form.Label>QR/Barcode Code</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Enter code..." 
                            name="code"
                            value={barCodeInputData.code}
                            onChange={(e) => setBarCodeInputData({...barCodeInputData, code: e.target.value})}
                            required
                        />
                        <Form.Control.Feedback type="invalid">Barcode Code is required.</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-space">
                        <Form.Label>Select QR/Barcode Type</Form.Label>
                        <Form.Select 
                            aria-label="Select QR/Barcode Type"
                            value={barCodeInputData.code_type}
                            onChange={(e) => setBarCodeInputData({...barCodeInputData, code_type: e.target.value})}
                            required
                        >
                            <option value="">Select QR/Barcode Type</option>
                                <option key={1} value="QR" >{"QR"}</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">Barcode Type is required.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Form.Group className="form-space">
                        <Form.Check 
                        type="checkbox"
                        id="isActive-checkbox"
                        label="Is Active"
                        checked={barCodeInputData.isActive}
                        onChange={(event) => handleCheckboxChange(event)}
                        />
                    </Form.Group>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                   <Button variant="primary" type="submit">
                    Save
                    </Button> 
                    <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                    </Button>                    
                </Modal.Footer>
                </Form>

            </Modal>

            {/* Delete Modal */}
            <Modal centered show={showDeleteModal} onHide={() => setshowDeleteModal(false)}>
                <Modal.Body className="text-center">
                    <div className="avatar avatar-xxxl bg-border-soft-danger rounded-circle text-danger mx-auto ">
                        <i className="bi bi-trash"></i>
                    </div>
                    <h4 className="fw-semibold mt-4">Are you sure?</h4>
                    <p className="text-muted fs-18">Do you really want to delete this record?</p>
                    <div className="d-flex gap-3">
                        <Button variant="light w-50" onClick={() => setshowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger w-50" onClick={handeleConfirmDelete}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
