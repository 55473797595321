import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Modal, Row, Col, Form } from "react-bootstrap";
import JSZip from "jszip";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { deleteFromAPI, getFromAPI, postToAPI, putToAPI } from '../Utils/utils';
import { toast } from 'react-toastify';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

export default function VehicleMapping({activeTab}) {
    // Modal
    const [showVehicleMappingModal, setshowVehicleMappingModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [VehicleMappingData, setVehicleMappingData] = useState();
    const [VehicleData, setVehicleData] = useState();
    const [DriversData, setDriversData] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [EditVehicleMappingID, setEditVehicleMappingID] = useState();
    const [DeleteVehicleMappingID, setDeleteVehicleMappingID] = useState();
    const [validated, setValidated] = useState(false);
    const InitialVehicleMapInputData = {
        name: "",
        vehicleId: 0,
        driverId: 0,
        isActive: true,
    }
    const [VehicleMapInputData, setVehicleMapInputData] = useState(InitialVehicleMapInputData);

    const fetchVehicleMappingData = async () => {
        try {
            getFromAPI(`vehicleMapping/`).then(data => {
                if (data) {
                    setVehicleMappingData(data);
                } else {
                    console.error('Unexpected data structure:', data.errors);
                }
            }).catch(error => {
                console.error('Error fetching user data:', error);
            });
        } catch (error) {
            
        }
    }

    const fetchVehicleData = async () => {
        try {
            getFromAPI(`vehicle/`).then(data => {
                if (data) {
                    setVehicleData(data);
                } else {
                    console.error('Unexpected data structure:', data);
                }
            }).catch(error => {
                console.error('Error fetching user data:', error);
            });
        } catch (error) {
            
        }
    }

    const fetchDriversData = async () => {
        try {
            getFromAPI(`driver/`).then(data => {
                if (data) {
                    setDriversData(data.drivers);
                } else {
                    console.error('Unexpected data structure:', data);
                }
            }).catch(error => {
                console.error('Error fetching user data:', error);
            });
        } catch (error) {
            
        }
    }

    const handleshoweditVehicleMappingModal = (title, id) => {
        setIsEdit(true);
        setEditVehicleMappingID(id);
        getFromAPI(`vehicleMapping/?id=${id}`).then(dataArr => {
            let data = dataArr[0];
            setVehicleMapInputData({...VehicleMapInputData, name:data.name, vehicleId:data.vehicleId, driverId:data.driverId, isActive:data.isActive});
        });
        handleshowVehicleMappingModal(title);
    }

    const handleshowVehicleMappingModal = (title) => {
        setModalTitle(title);
        setshowVehicleMappingModal(true);
    };

    const handleCloseModal = () => {
        UpdateVehicleMapDataTable();
        setshowVehicleMappingModal(false);
        setValidated(false);
        setIsEdit(false);
        setshowDeleteModal(false);
        setDeleteVehicleMappingID(null);
        setEditVehicleMappingID(null);
        setVehicleMapInputData(InitialVehicleMapInputData);
    };

    const handleDeleteData = (id) => {
        // Set the selected id and show the modal
        setDeleteVehicleMappingID(id);
        setshowDeleteModal(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const Response = await deleteFromAPI(`vehicleMapping/${DeleteVehicleMappingID}/`);
            if (Response.status) {
                toast.success('Vehicle map data Successfully deleted');
                handleCloseModal();
            } else {
                toast.error('Failed to delete vehicle map data');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An unexpected error occurred');
        }
    };

    const handleAddVehicleMapping = async (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
        } else {
            e.preventDefault(); // Prevent page reload
            setValidated(true);

            if(VehicleMapInputData.name.trim() === "") {
                toast.error("Enter valid vehicle mapping name");
                return
            }
            if(isEdit) {
                try {
                    const Response = await putToAPI(`vehicleMapping/${EditVehicleMappingID}/`, VehicleMapInputData);
                    if (Response.status) {
                        toast.success('Vehicle map data Successfully updated');
                        handleCloseModal();
                    } else {
                        toast.error('Failed to update vehicle map data');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error('An unexpected error occurred');
                }
            } else {
                const isDuplicateName = VehicleMappingData.some(VehicleMapping => {
                    return (
                        VehicleMapping.name.toLowerCase() === VehicleMapInputData.name.toLowerCase() &&
                        (!isEdit || VehicleMapping.id !== EditVehicleMappingID)
                    );
                });
    
                if (isDuplicateName) {
                    toast.error('Vehicle mapping name already exists');
                    return;
                }
                try {
                    const Response = await postToAPI(`vehicleMapping/`, VehicleMapInputData);
                    if (Response.status) {
                        toast.success("map data added successfully");
                        handleCloseModal();                
                    } else {
                        toast.error('Failed to update Ward');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error('An unexpected error occurred');
                }
            }
        }
    }

    const getDriverNameByID = (id) => {
        const d = DriversData.find(driver => driver.id === id);
        return d.FirstName + " " + d.LastName;
    }

    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (VehicleMappingData && VehicleData && DriversData && !$.fn.dataTable.isDataTable("#VehicleMappingDataTable")) {
            $("#VehicleMappingDataTable").DataTable({
                dom: 'Bflrtip',
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    }
                ],
                data: VehicleMappingData.map((data, i) => [
                    i + 1,
                    data.name,
                    VehicleData.find(vehicle => vehicle.id === data.vehicleId).Name,
                    getDriverNameByID(data.driverId),
                    data.isActive,
                    data.id,
                ]),
                columns: [
                    { title: "No." },
                    { title: "Mapping Name" },
                    { title: "Vehicle" },
                    { title: "Driver Name" },
                    {
                        title: "Status",
                        render: function (data, type, row) {
                            // TODO: Get status color & text.
                            let statusColor = row[4] ? 'success' : 'danger';
                            let statusText = row[4] ? 'Active' : 'in-Active';
                            return `
                            <span class="badge badge-soft-${statusColor}">${statusText}</span>
                          `;
                        }
                    },
                    {
                        title: "Action",
                        render: function (data, type, row) {
                            return `
                                <div class="d-flex">
                                    <button class="btn btn-soft-primary table-btn me-2"><i class="bi bi-pencil-square edit-vehicle-mapping" data-vm-id="${row[5]}"></i></button>
                                    <button class="btn btn-soft-danger table-btn"><i class="bi bi-trash3 delete-vehicle-mapping" data-vm-id="${row[5]}"></i></button>
                                </div>
                            `;
                        }
                    },
                ],
                autoWidth: false,
                columnDefs: [
                    {
                        targets: -1,
                        orderable: false,
                    },
                ],
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });
            // Event delegation to handle delete action
            document.querySelector('#VehicleMappingDataTable tbody').addEventListener('click', function (event) {
                if (event.target && event.target.classList.contains('edit-vehicle-mapping')) {
                    const vehiclemapping_id = event.target.getAttribute('data-vm-id');
                    handleshoweditVehicleMappingModal("Edit Vehicle Mapping", vehiclemapping_id);
                }
                if (event.target && event.target.classList.contains('delete-vehicle-mapping')) {
                    const vehiclemapping_id = event.target.getAttribute('data-vm-id');
                    handleDeleteData(vehiclemapping_id);
                }
            });

        }
    }, [VehicleMappingData, VehicleData, DriversData]);

    const UpdateVehicleMapDataTable = () => {
        const PODT = $('#VehicleMappingDataTable').DataTable();
        getFromAPI("vehicleMapping/")
            .then(response => {
                setVehicleMappingData(response);
                let DTData = response.map((data, i) => [
                    i + 1,
                    data.name,
                    VehicleData.find(vehicle => vehicle.id === data.vehicleId).Name,
                    getDriverNameByID(data.driverId),
                    data.isActive,
                    data.id,
                ]);
            PODT.clear().rows.add(DTData).draw();
            })
            .catch(error => {
            console.error('Error:', error);
            });
    }

    useEffect(()=>{
        fetchVehicleMappingData();
        fetchVehicleData();
        fetchDriversData();
    }, []);

    useEffect(()=>{
        fetchVehicleMappingData();
        fetchVehicleData();
        fetchDriversData();
    }, [activeTab]);

    return (
        <>
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between my-3">
                <Card.Title className="mb-0">Vehicle Mapping</Card.Title>
                <Button variant="primary" onClick={() => handleshowVehicleMappingModal("Add Vehicle Mapping")}>
                    <i className="bi bi-plus-square me-2"></i> Add Vehicle Mapping
                </Button>
            </div>

            <Table responsive bordered className="mb-0 table-nowrap" id="VehicleMappingDataTable">
                <thead className="table-light">
                </thead>
            </Table>

            {/* Vehicle Modal */}
            <Modal centered size="lg" show={showVehicleMappingModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={(e) => handleAddVehicleMapping(e)}>
                    <Modal.Body className="pb-0">
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="form-space">
                                    <Form.Label>Mapping Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Mapping Name..." name="vehiclename" value={VehicleMapInputData.name} onChange={(e) => setVehicleMapInputData({...VehicleMapInputData, name:e.target.value})} required isInvalid={validated && !VehicleMapInputData.name.trim()} />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter vehicle mapping name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                    <Form.Group className="form-space">
                                        <Form.Label>Vehicle Type</Form.Label>
                                        <Form.Select as="select" name="VehicleType" value={VehicleMapInputData.vehicleId} onChange={(e) => setVehicleMapInputData({...VehicleMapInputData, vehicleId:e.target.value})} required isInvalid={validated && (VehicleMapInputData.vehicleId === 0)} >
                                            <option value="">Select Vehicle</option>
                                            {VehicleData && VehicleData.map((vehicle) => vehicle.isActive ? (
                                                <option key={vehicle.id} value={vehicle.id}>
                                                    {vehicle.Name}
                                                </option>
                                            ) : null )}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please select vehicle.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="form-space">
                                        <Form.Label>Driver Name</Form.Label>
                                        <Form.Select as="select" name="DriverName" value={VehicleMapInputData.driverId} onChange={(e) => setVehicleMapInputData({...VehicleMapInputData, driverId:e.target.value})} required isInvalid={validated && (VehicleMapInputData.driverId === 0)} >
                                            <option value="">Select Driver</option>
                                            {DriversData && DriversData.map((driver) => driver.isActive ? (
                                                <option key={driver.id} value={driver.id}>
                                                {driver.FirstName + " " + driver.LastName}
                                                </option>
                                            ) : null)}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please select driver.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Check
                                    type="checkbox"
                                    id="default-checkbox"
                                    label="Is Active"
                                    checked={VehicleMapInputData.isActive}
                                    onChange={(e) => setVehicleMapInputData({...VehicleMapInputData, isActive:e.target.checked})}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="mt-1">
                        <Button variant="primary" type='submit'>Save</Button>
                        <Button variant="light" onClick={handleCloseModal}>Close</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* Delete Modal */}
            <Modal centered show={showDeleteModal} onHide={() => setshowDeleteModal(false)}>
                <Modal.Body className="text-center">
                    <div className="avatar avatar-xxxl bg-border-soft-danger rounded-circle text-danger mx-auto ">
                        <i className="bi bi-trash"></i>
                    </div>
                    <h4 className="fw-semibold mt-4">Are you sure?</h4>
                    <p className="text-muted fs-18">Do you really want to delete this record?</p>
                    <div className="d-flex gap-3">
                        <Button variant="light w-50" onClick={() => setshowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger w-50" onClick={handleConfirmDelete}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
